import request from '@/utils/request'
import constant from '@/constant'

function getCompanyRecordReport(formData){
  return request.post(constant.serverUrl + "/report/getCompanyRecordReport", formData);
}


export default {
  getCompanyRecordReport
}